import type { LinkProps } from "next/link";
import Link from "next/link";
import type { FC, PropsWithChildren, RefAttributes } from "react";
import { useLocaleContext } from "../contexts/LocaleProvider";
import type { AllLanguageCodes} from "@rimo/i18n/settings";
import { fallbackLng } from "@rimo/i18n/settings";

export type LocaleLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
  RefAttributes<HTMLAnchorElement> &
  LinkProps & {
    locale?: AllLanguageCodes;
  };

export const LocaleLink: FC<PropsWithChildren<LocaleLinkProps>> = (props) => {
  const { href, locale: propLocale } = props;
  const { locale: contextLocale } = useLocaleContext();
  const locale = propLocale || contextLocale;

  return <Link {...props} href={localizeHref(href, locale)} />;
};

export function localizeHref(href: LinkProps["href"], locale: AllLanguageCodes) {
  if (typeof href === "object") {
    // pathname がない場合は、そのまま返す
    if (href.pathname == null) {
      return href;
    }
    return {
      ...href,
      pathname: locale === fallbackLng ? href.pathname : `/${locale}${href.pathname}`,
    };
  }

  return locale === fallbackLng ? href : `/${locale}${href}`;
}
