import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { tv, type VariantProps } from "tailwind-variants";
import { Loader2 } from "lucide-react";

import { cn } from "@rimo/ui/lib/utils";

const buttonVariants = tv({
  base: "flex items-center justify-center whitespace-nowrap rounded-md text-md font-medium leading-none ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  variants: {
    variant: {
      primary: "bg-primary text-primary-foreground hover:bg-primary-hover",
      destructive:
        "border-2 border-destructive bg-destructive-background text-destructive hover:border-destructive-hover hover:bg-destructive-backgroundHover hover:text-destructive-hover",
      outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
      secondary: "bg-secondary text-secondary-foreground hover:bg-secondary-hover",
      ghost: "hover:bg-accent hover:text-accent-foreground",
      link: "text-link underline-offset-4 hover:underline",
    },
    size: {
      sm: "h-8 gap-x-1 px-3 text-xs [&>div]:gap-x-1 [&_svg:not(.loader)]:size-3.5",
      md: "h-10 gap-x-1.5 px-4 text-sm [&>div]:gap-x-1.5 [&_svg:not(.loader)]:size-4",
      lg: "h-12 gap-x-2 px-5 text-base [&>div]:gap-x-2 [&_svg:not(.loader)]:size-4.5",
      icon: "h-10 w-10",
      // (window size: > 640px => button size: md ) to (window size: < 640px => button size: sm)
      responsiveMdToSm:
        "h-10 gap-x-1.5 px-4 text-sm max-sm:h-8 max-sm:gap-x-1 max-sm:px-3 max-sm:text-xs max-sm:leading-none [&>div]:gap-x-1.5 max-sm:[&>div]:gap-x-1 [&_svg:not(.loader)]:size-4 max-sm:[&_svg:not(.loader)]:size-3.5",
    },
    rounded: {
      true: "rounded-full",
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
    rounded: false,
  },
} as const);
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, rounded, asChild = false, loading = false, children, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, rounded, className }),
          loading && "cursor-not-allowed opacity-50"
        )}
        ref={ref}
        disabled={loading || props.disabled}
        {...props}
      >
        {loading ? (
          <>
            <Loader2
              className={cn(
                "loader absolute animate-spin",
                size === "sm" && "size-4",
                size === "md" && "size-5",
                size === "lg" && "size-6"
              )}
            />
            <span
              className={cn(
                "invisible flex items-center justify-center",
                size === "sm" && "gap-x-1 [&>div]:gap-x-1",
                size === "md" && "gap-x-1.5 [&>div]:gap-x-1.5",
                size === "lg" && "gap-x-2 [&>div]:gap-x-2"
              )}
            >
              {children}
            </span>
          </>
        ) : (
          children
        )}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
export type { ButtonProps };
