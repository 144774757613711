import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import type { RimoVoiceGenericLPProps } from "./RimoVoiceGenericLP";
import { LocaleLink } from "../../components/LocaleLink";
import { useThemeMode } from "@rimo/ui-old";
import { usePathname } from "next/navigation";
import Image from "next/image";
import { useState, useEffect } from "react";

import { Button } from "@rimo/ui/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@rimo/ui/components/ui/dropdown-menu";
import { Menu, LogIn, ClipboardList } from "lucide-react";

const DATA = {
  writing: {
    ctaText: "無料トライアル",
    ctaLink: "/about",
  },
  proceedings: {
    ctaText: "無料トライアル",
    ctaLink: "https://app.spirinc.com/t/eIsKvaPHl1e6nv6Nsh19-/as/La5VFKfMzAbXh-SWdY-n1/confirm",
  },
  training: {
    ctaText: "お問い合わせ",
    ctaLink: "https://docs.google.com/forms/d/e/1FAIpQLSddfN5H1FOA5TZy0HxjTHiUOnkwx_yLaGB4iSzkp0Dj6qRdCg/viewform",
  },
  businessAnalysis: {
    ctaText: "お問い合わせ",
    ctaLink: "https://docs.google.com/forms/d/e/1FAIpQLSddfN5H1FOA5TZy0HxjTHiUOnkwx_yLaGB4iSzkp0Dj6qRdCg/viewform",
  },
  userResearch: {
    ctaText: "無料トライアル",
    ctaLink: "/about",
  },
  transcription: {
    ctaText: "無料トライアル",
    ctaLink: "/about",
  },
  botSpeak: {
    ctaText: "無料トライアル",
    ctaLink: "https://app.spirinc.com/t/eIsKvaPHl1e6nv6Nsh19-/as/La5VFKfMzAbXh-SWdY-n1/confirm",
  },
};

export const Header: React.FC<Pick<RimoVoiceGenericLPProps, "target">> = (props) => {
  const { customT } = useClientTranslation();

  const pathname = usePathname() || undefined;
  const data = DATA[props.target];
  const themeMode = useThemeMode();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleContactFormClick = (e: React.MouseEvent) => {
    e.preventDefault();

    // 現在のページ内に #contact-form 要素があるかチェック
    const contactForm = document.getElementById("contact-form");

    if (contactForm) {
      // 存在する場合は、現在のページ内でスクロール
      contactForm.scrollIntoView({ behavior: "smooth" });
    } else {
      // 存在しない場合は、通常のリンク動作（既存の動作を維持）
      window.location.href = `${documentRequestPathname}#contact-form`;
    }
  };

  const logoSrc = mounted && themeMode === "dark" ? "/assets/logo_white.svg" : "/assets/rimo_logo.svg";

  const FORM_AVAILABLE_PATHS = [
    "/usecases/meeting",
    "/usecases/user_research",
    "/usecases/business_analysis",
    "/usecases/training",
  ];

  const documentRequestPathname = FORM_AVAILABLE_PATHS.includes(pathname || "") ? pathname : "/about/voice";

  return (
    <div className="fixed left-0 top-0 z-[9990] w-full bg-background/80 backdrop-blur-md supports-[backdrop-filter]:bg-background/60">
      <div className="relative mx-auto flex h-14 items-center justify-between px-8 max-lg:px-3 max-sm:h-12">
        <div className="flex items-center gap-4">
          <LocaleLink href="/about/voice">
            <div className="flex items-center">
              <Image
                key={`logo-${mounted ? themeMode : "light"}`}
                src={logoSrc}
                alt="Rimo"
                width={80}
                height={32}
                className="h-auto w-auto max-sm:h-6"
                priority
              />

              <span className="ml-2 text-2xl font-extralight tracking-wider text-muted-foreground max-sm:text-lg">
                voice
              </span>
            </div>
          </LocaleLink>
        </div>

        <div className="flex items-center gap-3 text-sm max-sm:gap-2">
          <div className="hidden items-center gap-4 sm:flex">
            <LocaleLink href="/about" legacyBehavior>
              <a className="text-muted-foreground hover:text-foreground">{customT("ログイン")}</a>
            </LocaleLink>
          </div>

          <a href={`${documentRequestPathname}#contact-form`} onClick={handleContactFormClick}>
            <Button
              variant="outline"
              size="responsiveMdToSm"
              className="border-primary text-primary hover:bg-orange-50 hover:text-primary"
            >
              {customT("資料請求")}
            </Button>
          </a>

          <LocaleLink href={data.ctaLink || "/about"}>
            <Button variant="primary" size="responsiveMdToSm">
              {customT(data.ctaText)}
            </Button>
          </LocaleLink>

          <div className="block sm:hidden">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon" className="h-8 w-8">
                  <Menu />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent side="bottom" align="end" className="z-[9999] flex flex-col gap-2 py-3">
                <DropdownMenuItem asChild>
                  <a
                    href={`${documentRequestPathname}#contact-form`}
                    onClick={handleContactFormClick}
                    className="flex items-center gap-2 text-sm text-primary"
                  >
                    <ClipboardList size={16} />
                    {customT("資料請求")}
                  </a>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <LocaleLink href="/about" className="flex items-center gap-2 text-sm text-muted-foreground">
                    <LogIn size={16} />
                    {customT("ログイン")}
                  </LocaleLink>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </div>
  );
};
